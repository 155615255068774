nav{
    background-color: #1c1c1c !important;
    width: 100vw;
    height: 10vh;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    position: sticky; 
    top: 0 !important;
    z-index: 100;
}

nav img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

nav h1{
    margin-left: 7px;
}


nav .icons > *{
    padding: 0 7px;
    cursor: pointer;
}

nav .icons > :last-child{
    margin-right: 14px
}

@media only screen and (max-width: 786px) {
    nav .icons > *{
        padding: 0 7px;
    }
    nav .icons > :last-child{
        margin-right: 7px
    }
    
}

