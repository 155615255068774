.init{
    width: 100vw;
    height: 75vh;
    font-weight: 100;
}

.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}
.modal-content{
    width: 75%;
}
.posts img{
    object-fit: cover;
}

.init button{
    width: 25vh;
    height: 7vh;
    border:#fff solid 1px;
    border-radius: 16px;
    background-color: #1c1c1c;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Fredericka the Great';
    transition: all 0.2s ease-in-out;
}

.init button:hover{
    background-color: #fff;
    color: #1c1c1c;
}

.init input[type = "text"]{
    width: 30vh;
    height: 5vh;
    border-radius: 16px;
    background-color: grey;
    color: black;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-family: 'Fredericka the Great';
    transition: all 0.1s ease-in-out;
}