.post{
    width: 414px;
    overflow-x: hidden !important;
    opacity: 1;
}

.post img{
    width: 414px;
    max-height: 67.5vh;
}

.topbar{
    width: 414px;
    height: 60px;
    background-color: #1c1c1c
}
.dots{
    margin-right: 25px;
}
.left > *{
    margin: 0 10px;
}
.buttombar{
    height: 150px;
    width: 100%;
    background-color: #1c1c1c;
}
.buttombar > *{
    margin: 0 10px;
}

.buttombar .caption > *{
    margin-right: 10px;
    font-size: 15px;
}

.heart{
    margin-right: 10px;
}

.red{
    color: #ff3242;
}
.modal{
    width: 300px;
    height: 50vh;
    background-color: white !important;
    border-radius: 25px;
    position: fixed;
    z-index: 100;
    top: 25%;
    border: 5px solid black;
    overflow: hidden !important;
}
.modalOpen{
    opacity: 0.5;
}
.modal h3{
    color: black;
    margin: 25px 0;
    border-bottom: black solid 3px;
}

.topbar img{
    width: 25px;
    height: 25px;
    clip-path: circle();
}

@media only screen and (max-width: 786px) {
    .post{
        width: 100vw;
    }
    .topbar{
        width: 100vw;
    }
}

