.notifications{
    overflow-y: scroll;
    flex-direction: column;
}

.notifications::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.notifications h1{
    margin-top: 25vh;
}

.notif{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #1c1c1c !important;
    color: #fff;
    width: 50vh;
    height: 15vh;
    padding: 0.5vh 1vw;
    box-sizing: content-box;
}