
.loginContainer{
    width: 100vw;
    height: 85vh;
}

.loginContainer img{
    width: 50vh;
}

