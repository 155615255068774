.posts{
    overflow: scroll !important;
    opacity: 1;
}

::-webkit-scrollbar {
    width: 0px;
    background: none;
}
.posts h1{
    margin-top: 25vh;
}