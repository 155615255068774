body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

body{
  overflow-x: hidden;
}
.pointer{
  cursor: pointer;
}
a{
  text-decoration: none;
  color: white;
}

.flexbox{
  display: flex;
}

.column{
  flex-direction: column;
}

.center{
  text-align: center;
  align-items: center;
  justify-content: center;
}
